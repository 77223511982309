import React, { Component } from "react";
class StudentForm extends Component {
  render() {
    const { student, handleInputUpdate, handleSubmitUpdate } = this.props;
    return (
      <section
        className="card"
        style={{
          padding: 20,
        }}
      >
        <label
          style={{
            fontSize: 18,
          }}
        >
          <b>Data Siswa</b>
        </label>

        <hr
          style={{
            margin: "0px 0px 10px 0px",
          }}
        />

        {student.PREFIX === "INKA" || student.PREFIX === "DEMO" ? (
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=id.schooltalk.app"
              target="__blank"
            >
              <b>Go To Elite School (Android)</b>
            </a>
            <br />
            <a
              href="https://apps.apple.com/id/app/elite-school/id6504702874?l=id"
              target="__blank"
            >
              <b>Go To Elite School (iOS)</b>
            </a>
            <br />
            <br />
          </div>
        ) : null}
        <div className="form-group">
          <label>{"Nama Lengkap Siswa"}*</label>
          <input
            className="form-control"
            disabled
            required
            value={student.name}
          />
        </div>
        <div className="form-group">
          <label>{"Nama Panggilan"}*</label>
          <input
            className="form-control"
            disabled
            required
            value={student.nickName}
          />
        </div>
        <div className="form-group">
          <label>{"Pin (Di set oleh Orang Tua)"}</label>
          <input
            className="form-control"
            value={student.pin}
            type="pin"
            maxLength={6}
            onChange={handleInputUpdate("pin")}
            style={{
              width: "100px",
            }}
            disabled={student.disabledPin}
          />
        </div>
        <div className="form-group">
          <label>{"NIS/NIPD"}*</label>
          <input
            className="form-control"
            disabled
            required
            value={student.regNumber}
          />
        </div>
        <div className="form-group">
          <label>{"NISN"}</label>
          <input
            className="form-control"
            disabled
            value={student.nationalRegNumber}
          />
        </div>
        <div className="form-group">
          <label>{"Kelas"}</label>
          <input
            disabled
            style={{ maxWidth: 250 }}
            className="form-control"
            value={student.Classroom ? student.Classroom.name : ""}
          />
        </div>
        <div className="form-group">
          <label>{"Jenis Kelamin"}</label>
          <select
            className="form-control"
            disabled
            style={{ maxWidth: 250 }}
            value={student.gender}
          >
            <option value="" disabled>
              {"Pilih Jenis Kelamin"}
            </option>
            <option value="Laki-laki">{"Laki-laki"}</option>
            <option value="Perempuan">{"Perempuan"}</option>
          </select>
        </div>
        <div className="form-group">
          <label>{"Tempat Lahir"}</label>
          <input className="form-control" disabled value={student.birthCity} />
        </div>
        <div className="form-group">
          <label>{"Tanggal Lahir"}</label>
          <input
            className="form-control"
            disabled
            style={{ maxWidth: 250 }}
            type="date"
            value={student.birthDate}
          />
        </div>
        <div className="form-group">
          <label>{"NIK"}</label>
          <input className="form-control" disabled value={student.nin} />
        </div>
        <div className="form-group">
          <label>{"Agama"}</label>
          <select
            className="form-control"
            disabled
            value={student.religion}
            style={{ maxWidth: 250 }}
          >
            <option value="" disabled>
              {"Pilih Agama"}
            </option>
            <option value="ISLAM">{"Islam"}</option>
            <option value="KATOLIK">{"Kristen Katolik"}</option>
            <option value="PROTESTAN">{"Kristen Protestan"}</option>
            <option value="HINDU">{"Hindu"}</option>
            <option value="BUDHA">{"Budha"}</option>
            <option value="KONGHUCU">{"Konghucu"}</option>
            <option value="LAINNYA">{"Lainnya"}</option>
          </select>
        </div>
        <div className="form-group">
          <label>{"Alamat OrangTua"}</label>
          <input
            className="form-control"
            disabled
            value={student.parentAddress}
          />
        </div>
        <div className="form-group">
          <label>{"Alamat"}</label>
          <input className="form-control" disabled value={student.address} />
        </div>
        {"RT" ? (
          <div className="form-group">
            <label>{"RT"}</label>
            <input
              className="form-control"
              disabled
              type="number"
              min="0"
              value={student.rt ? student.rt : 0}
              style={{ maxWidth: 150 }}
            />
          </div>
        ) : null}
        {"RW" ? (
          <div className="form-group">
            <label>{"RW"}</label>
            <input
              className="form-control"
              disabled
              type="number"
              min="0"
              value={student.rw ? student.rw : 0}
              style={{ maxWidth: 150 }}
            />
          </div>
        ) : null}
        {"Dusun" ? (
          <div className="form-group">
            <label>{"Dusun"}</label>
            <input className="form-control" disabled value={student.dusun} />
          </div>
        ) : null}
        {"Kelurahan" ? (
          <div className="form-group">
            <label>{"Kelurahan"}</label>
            <input
              className="form-control"
              disabled
              value={student.kelurahan}
            />
          </div>
        ) : null}
        {"Kecamatan" ? (
          <div className="form-group">
            <label>{"Kecamatan"}</label>
            <input
              className="form-control"
              disabled
              value={student.kecamatan}
            />
          </div>
        ) : null}
        {"Kota" ? (
          <div className="form-group">
            <label>{"Kota"}</label>
            <input className="form-control" disabled value={student.city} />
          </div>
        ) : null}
        {"Kota Asal" ? (
          <div className="form-group">
            <label>{"Kota Asal"}</label>
            <input
              className="form-control"
              disabled
              value={student.originCity}
            />
          </div>
        ) : null}
        <div className="form-group">
          <label>{"Propinsi"}</label>
          <input className="form-control" disabled value={student.province} />
        </div>
        <div className="form-group">
          <label>{"Kode Pos"}</label>
          <input className="form-control" disabled value={student.postalCode} />
        </div>
        <div className="form-group">
          <label>{"Jenis Tinggal"}</label>
          <input className="form-control" disabled value={student.typeOfStay} />
        </div>
        <div className="form-group">
          <label>{"Transportasi"}</label>
          <input
            className="form-control"
            disabled
            value={student.transportation}
          />
        </div>
        <div className="form-group">
          <label>{"No Telp"}</label>
          <input
            className="form-control"
            disabled
            value={student.phoneNumber}
          />
        </div>
        <div className="form-group">
          <label>{"No Hp"}</label>
          <input
            className="form-control"
            disabled
            value={student.cellphoneNumber}
          />
        </div>
        <div className="form-group">
          <label>{"Email"}</label>
          <input className="form-control" disabled value={student.email} />
        </div>
        {"SKHUN" ? (
          <div className="form-group">
            <label>{"SKHUN"}</label>
            <input className="form-control" disabled value={student.skhun} />
          </div>
        ) : null}
        {"Penerima KPS" ? (
          <div className="form-group">
            <label>{"Penerima KPS"}</label>
            <select
              className="form-control"
              disabled
              value={student.kpsReceiver}
              style={{ maxWidth: 250 }}
            >
              <option value="" disabled>
                {"Pilih Penerima KPS"}
              </option>
              <option value="Ya">{"Ya"}</option>
              <option value="Tidak">{"Tidak"}</option>
            </select>
          </div>
        ) : null}
        {"No KPS" ? (
          <div className="form-group">
            <label>{"No KPS"}</label>
            <input
              className="form-control"
              disabled
              value={student.kpsNumber}
            />
          </div>
        ) : null}
        <div className="form-group">
          <label>{"Rombongan Belajar"}</label>
          <input
            className="form-control"
            disabled
            value={student.Classroom ? student.Classroom.name : ""}
          />
        </div>
        <div className="form-group">
          <label>{"No Ujian Nasional"}</label>
          <input
            className="form-control"
            disabled
            value={student.nationalExamRegNumber}
          />
        </div>
        <div className="form-group">
          <label>{"No Ijazah"}</label>
          <input
            className="form-control"
            disabled
            value={student.certificateNumber}
          />
        </div>
        {"Penerima KIP" ? (
          <div className="form-group">
            <label>{"Penerima KIP"}</label>
            <select
              className="form-control"
              disabled
              value={student.kipReceiver}
              style={{ maxWidth: 250 }}
            >
              <option value="" disabled>
                {"Status penerima KIP"}
              </option>
              <option value="Ya">{"Ya"}</option>
              <option value="Tidak">{"Tidak"}</option>
            </select>
          </div>
        ) : null}
        {"Nama Penerima KIP" ? (
          <div className="form-group">
            <label>{"Nama Penerima KIP"}</label>
            <input className="form-control" disabled value={student.kipName} />
          </div>
        ) : null}
        {"No KIP" ? (
          <div className="form-group">
            <label>{"No KIP"}</label>
            <input
              className="form-control"
              disabled
              value={student.kipNumber}
            />
          </div>
        ) : null}
        {"No KKS" ? (
          <div className="form-group">
            <label>{"No KKS"}</label>
            <input
              className="form-control"
              disabled
              value={student.kksNumber}
            />
          </div>
        ) : null}
        <div className="form-group">
          <label>{"No Akta Lahir"}</label>
          <input
            className="form-control"
            disabled
            value={student.regNumberBirthCertificate}
          />
        </div>
        <div className="form-group">
          <label>{"Bank"}</label>
          <input className="form-control" disabled value={student.bank} />
        </div>
        <div className="form-group">
          <label>{"No Rekening"}</label>
          <input
            className="form-control"
            disabled
            value={student.bankAccountNumber}
          />
        </div>
        <div className="form-group">
          <label>{"Rekening Atas Nama"}</label>
          <input
            className="form-control"
            disabled
            value={student.accountName}
          />
        </div>
        <div className="form-group">
          <label>{"Layak PIP (usulan sekolah)"}</label>
          <select
            className="form-control"
            disabled
            value={student.pip}
            style={{ maxWidth: 250 }}
          >
            <option value="" disabled>
              {"Status Penerimaan PIP"}
            </option>
            <option value="Ya">{"Ya"}</option>
            <option value="Tidak">{"Tidak"}</option>
          </select>
        </div>
        <div className="form-group">
          <label>{"Alasan menerima PIP"}</label>
          <input
            className="form-control"
            disabled
            value={student.pipDescription}
          />
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label>{"Asal Sekolah"}</label>
              <input
                className="form-control"
                disabled
                value={student.previousSchool}
              />
            </div>
            <div className="col-md-6">
              <label>{"Status Sekolah"}</label>
              <select
                className="form-control"
                disabled
                value={student.previousSchoolStatus}
              >
                <option value="" selected>
                  {"Status Sekolah Asal"}
                </option>
                <option value="Negeri">{"Negeri"}</option>
                <option value="Swasta">{"Swasta"}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>{"Alamat Asal Sekolah"}</label>
          <textarea
            className="form-control"
            disabled
            value={student.previousSchoolAddress}
          />
        </div>
        <div className="form-group">
          <label>{"Anak Ke"}</label>
          <input
            className="form-control"
            disabled
            type="number"
            style={{ maxWidth: 250 }}
            value={student.childOrder ? student.childOrder : 0}
          />
        </div>
        <div className="form-group">
          <label>{"Dari Saudara"}</label>
          <input
            className="form-control"
            disabled
            value={student.fromNumberOfSiblings}
          />
        </div>
        <div className="form-group">
          <label>{"Status Anak"}</label>
          <select className="form-control" disabled value={student.childStatus}>
            <option value="" disabled>
              {"Pilih Status Anak"}
            </option>
            <option value="Kandung">{"Kandung"}</option>
            <option value="Tiri">{"Tiri"}</option>
            <option value="Angkat">{"Angkat"}</option>
          </select>
        </div>
        <div className="form-group">
          <label>{"Golongan Darah"}</label>
          <select className="form-control" disabled value={student.bloodType}>
            <option value="" disabled>
              {"Pilih Golongan Darah"}
            </option>
            <option value="O">O</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="AB">AB</option>
          </select>
        </div>
        <div className="form-group">
          <label>{"Riwayat Penyakit"}</label>
          <textarea
            rows="3"
            className="form-control"
            disabled
            value={student.diseaseHistory}
          />
        </div>
        <div className="form-group">
          <label>{"Alergi Makanan"}</label>
          <input
            className="form-control"
            disabled
            value={student.foodAllergic}
          />
        </div>
        <div className="form-group">
          <label>{"Alergi Minuman"}</label>
          <input
            className="form-control"
            disabled
            value={student.drinkAllergic}
          />
        </div>
        <div className="form-group">
          <label>{"Berat Badan (Kg)"}</label>
          <input
            className="form-control"
            disabled
            style={{ maxWidth: 250 }}
            type="number"
            value={student.weight ? student.weight : 0}
          />
        </div>
        <div className="form-group">
          <label>{"Tinggi Badan (cm)"}</label>
          <input
            className="form-control"
            disabled
            style={{ maxWidth: 250 }}
            placeholder="Isikan berat badan siswa"
            type="number"
            value={student.height ? student.height : 0}
          />
        </div>
        <div className="form-group">
          <label>{"Jarak Tempat Tinggal (m)"}</label>
          <input
            className="form-control"
            disabled
            style={{ maxWidth: 250 }}
            placeholder="Jarak tempat tinggal siswa dengan sekolah"
            type="number"
            value={student.homeDistance ? student.homeDistance : 0}
          />
        </div>
        <div className="form-group">
          <label>{"Waktu tempuh ke Sekolah"}</label>
          <input
            className="form-control"
            disabled
            style={{ maxWidth: 250 }}
            placeholder="Waktu tempuh ke sekolah"
            type="number"
            value={student.travelTime ? student.travelTime : 0}
          />
        </div>
        {"Jumlah Saudara Kandung" ? (
          <div className="form-group">
            <label>{"Jumlah Saudara Kandung"}</label>
            <input
              className="form-control"
              disabled
              value={student.numberOfSiblings}
            />
          </div>
        ) : null}
        <div className="form-group">
          <label>{"Berkebutuhan Khusus"}</label>
          <input className="form-control" disabled value={student.disability} />
        </div>
        <div className="form-group">
          <label>{"Bahasa"}</label>
          <input className="form-control" disabled value={student.language} />
        </div>
        <div className="form-group">
          <label>{"Diterima Di Sekolah Ini Pada"}</label>
          <input
            className="form-control"
            disabled
            type="date"
            value={student.enrolledAt}
          />
        </div>
        <div className="form-group">
          <label>{"Diterima Di Sekolah Ini Pada Jenjang"}</label>
          <input
            className="form-control"
            value={student.enrolledAtGrade}
            disabled
          />
        </div>

        <button
          className="btn btn-md btn-block btn-primary"
          onClick={handleSubmitUpdate}
        >
          <i className="fa fa-save" /> Simpan
        </button>
      </section>
    );
  }
}

export default StudentForm;
